import Container from "react-bootstrap/Container";
import { usePageTitle } from "../../common/PageTitle";
import Button from "react-bootstrap/Button"
import { Link } from "react-router-dom";
import H2Title from "../../layout/H2Title";
import H3Title from "../../layout/H2Title";
import CtaButton from "../../common/CtaButton";
import BioButton from "../../common/BioButton";
import img from "../../../images/kikki-young-small.jpg"



export default function HomePage() {
	const [page_title, setPageTitle] = usePageTitle("Kikki Aikio homepage | Sami musician");
	return (
		<main className="wrapper">
			<Container>
				<hr className="top__divider"/>
				<section className="text__content">
				<div className="">
					<p className="mini__heading" >New Release</p>
						<H2Title title="En el nombre del SEÑOR" />
						<p>On June 21st, 2024, Kikki Aikio will release her newest album, "En el nombre del SEÑOR."</p>
						<p>Kikki collaborated with Simon H. Jernsletten to adapt the songs into Spanish. Simon is pursuing a PhD in Social and Human Sciences in Colombia, where his research focuses on human rights defenders in the state of Cauca.</p>
						<p>Some of these songs have already been recorded in other languages; English, Sámi, Norwegian and Italian.</p>
						<iframe 
						className="home_iframe"
						src="https://open.spotify.com/embed/album/2SBpUP2cEuIJIVdlvVTiL4?utm_source=generator&theme=0" 
						width="100%" 
						height="352"
						allowfullscreen="" 
						allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
						title="En el nombre del SEÑOR"
						loading="lazy">
						</iframe>
					</div>
				</section>
				<CtaButton cta="Go to album"/>
				{/* <hr /> */}
				<section className="hero__container">
					<img className="hero__image" src={img} alt="Kikkio Aikio profile image"/>
				</section>
				<section className="text__content">
				<div className="">
					<p className="mini__heading"></p>
						<H2Title title="The Greatest" />
						<p>May 16, 2024 marked the release of Kikki Aikio's album "The Greatest".
						</p>
						<p>The songs were recorded last spring (spring '23) and have since matured, as the production process took quite some time. Some of these songs have already been recorded in other languages, including Sámi, Norwegian, and Italian.
						</p>
						<p>The Bible has been a heavy inspiration for this album, and it contains texts from both the Old and the New Testament.
						</p>
						<iframe 
						className="home_iframe"
						src="https://open.spotify.com/embed/album/2lmrXMMHoiGkVwRxoAmOxv?utm_source=generator&theme=0" 
						width="100%" 
						height="152"
						allowfullscreen="" 
						allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
						title="The Greatest">
						</iframe>
						<div className="cite__wrapper">
						</div>
					</div>
				</section>
				<CtaButton cta="See full discography"/>
				<section className="text__content">
					<div className="quote__wrapper">
						<q className="quote">For all of you are children of the light and children of the day. We are not of the night or of darkness.</q>
						<br/>
						<div className="cite__wrapper">
						<cite className="cite">- Thessalonians 5:5</cite>
						</div>
					</div>
					<hr />
				</section>
				<section className="text__content">
					<div className="">
						<H2Title title="20 years anniversary" />
						<p>In 2023, the Christian Sámi singer songwriter Kikki Aikio celebrated 20 years as a recording artist with numerous albums in Sámi, English and Norwegian.</p>
					</div>
					<BioButton link="/bio" cta="Biography"/>
				</section>
			</Container>
		</main>
	);
}
